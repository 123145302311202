
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;

}

nav {
  background-color: rgba(255, 255, 255, 0.1); 
  padding: 10px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px); 
  border-radius: 8px; 
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
}

nav ul li a:hover,
nav ul li a.active {
  background-color: #dfe0e262;
  color: white;
}


main {
  padding: 20px;
  text-align: center;
}

