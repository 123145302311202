.book-list-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: linear-gradient(145deg, #1e2024, #22262e);
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  font-family: 'Inter', sans-serif;
  color: #ecf0f1;
}

.book-list-container h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: #f5f6fa;


}

.add-book-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

input[type="text"], select {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #ecf0f1;
  font-size: 1rem;
}

input[type="text"]:focus, select:focus {
  border: 1px solid #0984e3;
  outline: none;
  background: rgba(255, 255, 255, 0.2);
}

select option {
  background: #2c3e50;
  color: #ecf0f1;
}

.add-button {
  background: linear-gradient(90deg, #6c5ce7, #a29bfe);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  transition: transform 0.3s ease, background 0.3s ease;
}

.add-button:hover {
  background: linear-gradient(90deg, #6c5ce7, #74b9ff);
  transform: translateY(-2px);
}

.book-list {
  list-style: none;
  padding: 0;
  max-height: 50vh;
  overflow-y: auto;
  margin: 0;
}

.book-list::-webkit-scrollbar {
  width: 6px;
}

.book-list::-webkit-scrollbar-thumb {
  background: #6c5ce7;
  border-radius: 3px;
}

.book-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.book-item:hover {
  box-shadow: 0 6px 12px rgba(116, 185, 255, 0.2);
}

.book-details h3 {
  margin: 0;
  color: #74b9ff;
  font-weight: bold;
}

.book-details p {
  margin: 4px 0;
  color: #dfe6e9;
}

.book-item button {
  background: linear-gradient(90deg, #ff7675, #e17055);
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
}

.book-item button:hover {

  transform: scale(1.05);
}

.book-item button:active {
  transform: scale(0.95);
}
