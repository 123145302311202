@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.about-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background: linear-gradient(145deg, #1e2024, #22262e);
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  transform: scale(0.95);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-container:hover {
  transform: scale(1);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
}

.about-container h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  color: #ecf0f1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInDown 1s ease;
}

.about-container p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #ecf0f1;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease;
}

.about-container p span {
  color: #ffdd59;
  font-weight: bold;
}

.cta-button {
  background: #ffffff;
  color: black;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  animation: bounceIn 1.5s ease infinite;
}

.cta-button:hover {
  background: #161616;
  color: #ffffff;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceIn {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-7px);
  }
  60% {
    transform: translateY(-3px);
  }
}
